@config "../tailwind.custom.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--background-image: url("");
}

* {
	line-height: 1;
}

#main {
	/* 70px == 4.375rem == height + padding of nav */
	margin-top: 4.375rem;
	position: relative;
}

/* Typography */
h1,
.h1 {
	font-size: 40px !important;
}
h2,
.h2 {
	font-size: 20px !important;
}
h3,
.h3 {
	font-size: 15px !important;
}
p,
.p {
	font-size: 15px !important;
	line-height: 20px;
}

/* Buttons */
.btn {
	font-size: 0.875rem;
	padding: 1.125rem 1.875rem;

	margin-bottom: 30px;
	text-align: center;
	transition: all 0.3s;
}

@layer components {
	.btn-primary {
		@apply rounded-btn_border_radius bg-btn_primary text-btn_primary_content hover:bg-btn_primary_hover hover:text-btn_primary_hover_content border border-btn_primary_border_color hover:border-btn_primary_hover_border_color !important;
		font-weight: bold !important;
	}
	.btn-secondary {
		@apply rounded-btn_border_radius bg-btn_secondary text-btn_secondary_content hover:bg-btn_secondary_hover hover:text-btn_secondary_hover_content border border-btn_secondary_border_color hover:border-btn_secondary_hover_border_color !important;
	}

	.nav-item-secondary {
		@apply bg-menu_child text-menu_child_text;
	}
	.nav-item-secondary path {
		@apply fill-menu_child_text;
	}

	
	.nav-item.navLinkSelect{
		@apply bg-highlighted_item/[.8] text-highlighted_item_content;
	}
	
	.nav-item-secondary:hover,
	.nav-item-secondary.active {
		@apply bg-highlighted_item text-highlighted_item_content;
	}
	
	.nav-item.navLinkSelect path,
	.nav-item-secondary:hover path,
	.nav-item-secondary.active path
	{
		@apply fill-highlighted_item_content;
	}
	
	.nav-item.navLinkSelect .arrow-icon-brand,
	.nav-item-secondary:hover .arrow-icon-brand,
	.nav-item-secondary.active .arrow-icon-brand,
	.arrow-icon-brand.active{
		-webkit-filter: brightness(0) invert(1); /* Safari/Chrome */
		filter: brightness(0) invert(1);
	}
}

/* General css */

.transition-all {
	transition-duration: 400ms !important;
}

/* Tailwind override */
.border-b-3 {
	border-bottom-width: 3px;
}

.z-60 {
	z-index: 60;
}

.z--1 {
	z-index: -1 !important;
}

ul {
	list-style: disc;
}
ul > li {
	line-height: 20px;
	font-size: 15px;
}

hr {
	border-color: black;
	color: black;
	opacity: 1;
}

#nav {
	min-height: 70px;
}


.dialog-body p{
	margin: 1rem 0;
}
.dialog-body ol{
	list-style-position:inside;
	list-style-type: decimal;
	margin: 1.5rem 0;
	padding-left: 10px;
}

.dialog-body ol li{
	line-height:20px
}