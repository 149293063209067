@config "../../tailwind.custom.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;

.Loading .loader {
	background: white;
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 99999;
}

.Loading .loader-inner {
	bottom: 0;
	height: 60px;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 100px;
}

.Loading .loader-line-wrap {
	animation: spin 2000ms cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
	box-sizing: border-box;
	height: 50px;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	transform-origin: 50% 100%;
	width: 100px;
}
.Loading .loader-line {
	border: 4px solid transparent;
	border-radius: 100%;
	box-sizing: border-box;
	height: 100px;
	left: 0;
	margin: 0 auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 100px;
}
.Loading .loader-line-wrap:nth-child(1) {
	animation-delay: -50ms;
}
.Loading .loader-line-wrap:nth-child(2) {
	animation-delay: -100ms;
}
.Loading .loader-line-wrap:nth-child(3) {
	animation-delay: -150ms;
}
.Loading .loader-line-wrap:nth-child(4) {
	animation-delay: -200ms;
}
.Loading .loader-line-wrap:nth-child(5) {
	animation-delay: -250ms;
}

.Loading .loader-line-wrap:nth-child(1) .loader-line {
	height: 90px;
	width: 90px;
	top: 7px;
}
.Loading .loader-line-wrap:nth-child(2) .loader-line {
	height: 76px;
	width: 76px;
	top: 14px;
}
.Loading .loader-line-wrap:nth-child(3) .loader-line {
	height: 62px;
	width: 62px;
	top: 21px;
}
.Loading .loader-line-wrap:nth-child(4) .loader-line {
	height: 48px;
	width: 48px;
	top: 28px;
}
.Loading .loader-line-wrap:nth-child(5) .loader-line {
	height: 34px;
	width: 34px;
	top: 35px;
}

.Loading .loader-line-wrap:nth-child(even) .loader-line {
	border-color: theme(colors.menu_divider);
}
.Loading .loader-line-wrap:nth-child(odd) .loader-line {
	border-color: theme(colors.footer);
}

@keyframes spin {
	0%,
	15% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
